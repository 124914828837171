/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Image } from '@graphcommerce/image'
import { RenderType, responsiveVal } from '@graphcommerce/next-ui'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import arrowLeft from '../../assets/images/arrow-left-outline.svg'
import arrowRight from '../../assets/images/arrow-right-outline.svg'
import useWindowSize from '../../hooks/useWindowSize'
import { productListRenderer } from '../ProductListItems/productListRenderer'
import { MostViewedGroupFragment } from './MostViewedGroup.gql'

type HomePageRelatedProductGroupProps = MostViewedGroupFragment & {
  classes?: {
    list?: string
  }
  displayOnMinicart?: boolean
  block_title?: string | null | undefined
  product_uid?: string
  loading?: boolean
  callback?: () => void
}

export function HomePageRelatedProductGroup(props: HomePageRelatedProductGroupProps) {
  const { items, block_title, classes, displayOnMinicart, product_uid, loading, callback } = props
  const { isMobile } = useWindowSize()

  const handleCallback = () => {
    if (callback) {
      callback()
    }
  }

  if (!items || items.length === 0) return null

  return (
    <div className={`flex w-full items-center justify-center ${displayOnMinicart ? 'mt-25' : ''}`}>
      <div
        className={`w-full max-w-maxContentWidth md:mb-5 ${
          displayOnMinicart ? 'mx-10' : 'mx-[20px]'
        }`}
      >
        <div className='flex w-full'>
          <CarouselProvider
            naturalSlideWidth={5}
            naturalSlideHeight={1}
            totalSlides={items?.length}
            // eslint-disable-next-line no-nested-ternary
            visibleSlides={isMobile ? 2 : 8}
            infinite
            isIntrinsicHeight
            className='grid w-auto grid-cols-[5fr_90fr_5fr] gap-[10px] self-center md:w-full'
          >
            <ButtonBack className='flex items-center justify-center justify-self-start hover:scale-110'>
              <Image src={arrowLeft} alt='Back' layout='fixed' unoptimized height={25} width={25} />
            </ButtonBack>
            <Slider className='w-full gap-[40px] align-middle'>
              {items?.map((item, index) =>
                item ? (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <Slide
                    index={index}
                    className='flex w-full min-w-[200px] max-w-none items-center justify-center px-5 md:max-w-[260px]'
                    onClick={handleCallback}
                  >
                    <RenderType
                      key={item.uid ?? ''}
                      renderer={productListRenderer}
                      sizes={responsiveVal(200, 400)}
                      titleComponent='h3'
                      columns={8}
                      {...item}
                    />
                  </Slide>
                ) : null,
              )}
            </Slider>
            <ButtonNext className='flex items-center justify-center justify-self-end hover:scale-110'>
              <Image
                src={arrowRight}
                alt='Back'
                layout='fixed'
                unoptimized
                height={25}
                width={25}
              />
            </ButtonNext>
          </CarouselProvider>
        </div>
      </div>
    </div>
  )
}
