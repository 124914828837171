import { useQuery } from '@graphcommerce/graphql'
import { Dispatch, SetStateAction } from 'react'
import { ProductCardSkeleton } from '../Skeleton/ProductCardSkeleton'
import { AmMostviewedGroupsDocument } from './AmMostviewedGroups.gql'
import { AmMostviewedGroupsByPositionDocument } from './AmMostviewedGroupsByPosition.gql'
import { HomePageRelatedProductGroup } from './HomePageRelatedProductGroup'
import { RelatedProductGroup } from './RelatedProductGroup'

type HomePageRelatedProductGroupsProps = {
  uid?: string
  position: string
  classes?: {
    list?: string
  }
  displayOnMinicart?: boolean
  block_title?: string
  callback?: () => void
  setGridDisplay?: Dispatch<SetStateAction<'bestsellers' | 'newitems'>>
  gridDisplay?: 'bestsellers' | 'newitems'
}

export function HomePageRelatedProductGroups(props: HomePageRelatedProductGroupsProps) {
  const {
    position,
    uid,
    classes,
    block_title,
    displayOnMinicart,
    callback,
    setGridDisplay,
    gridDisplay,
  } = props
  const document = uid ? AmMostviewedGroupsDocument : AmMostviewedGroupsByPositionDocument
  const { data, loading } = useQuery(document, {
    variables: uid
      ? {
          uid,
          position,
        }
      : {
          position,
        },
    fetchPolicy: 'cache-and-network',
  })

  const groups = data?.amMostviewedGroups?.items

  const handleChangeDisplay = (gridType: 'bestsellers' | 'newitems') => {
    setGridDisplay?.(gridType)
  }

  return (
    <>
      {loading && (
        <div className='mt-25 flex w-full items-center justify-center'>
          <div
            className='w-full max-w-maxContentWidth
                md:mb-5'
          >
            <div className='relative flex items-center py-10 pb-6 lg:mx-0'>
              <span className='Type-XXL-Medium md:Type-XXXL-Medium text-black'>
                Your favorites, perfectly printed every time.
              </span>
            </div>
            <div className='mb-5 flex gap-3'>
              <button
                type='button'
                onClick={() => handleChangeDisplay('bestsellers')}
                className={`flex w-auto items-center justify-center rounded-[20px] border-[1px] border-bright-templi-purple px-2 py-[6px] text-center  hover:shadow-md ${
                  gridDisplay === 'bestsellers'
                    ? ' bg-bright-templi-purple text-pure-white hover:bg-bright-templi-purple-hover'
                    : ' bg-none text-bright-templi-purple-hover hover:border-bright-templi-purple-hover hover:bg-bright-templi-purple-hover hover:text-pure-white'
                }`}
              >
                <span className='Type-Large-Regular truncate whitespace-nowrap px-2'>
                  Best Sellers
                </span>
              </button>
              <button
                type='button'
                onClick={() => handleChangeDisplay('newitems')}
                className={`flex w-auto items-center justify-center rounded-[20px] border-[1px] border-solid border-bright-templi-purple bg-none px-2 py-[6px] text-center hover:bg-bright-templi-purple-hover hover:text-pure-white hover:shadow-md ${
                  gridDisplay === 'newitems'
                    ? ' bg-bright-templi-purple text-pure-white hover:bg-bright-templi-purple-hover'
                    : ' bbg-none text-bright-templi-purple-hover hover:border-bright-templi-purple-hover hover:bg-bright-templi-purple-hover hover:text-pure-white'
                }`}
              >
                <span className='Type-Large-Regular truncate whitespace-nowrap px-2'>New</span>
              </button>
            </div>
            <div className={`grid grid-cols-2 gap-[20px] md:grid-cols-3 ${classes?.list || ''}`}>
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <div className='hidden md:flex'>
                <ProductCardSkeleton />
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <div className=''>
          <div className='relative flex items-center py-10 pb-6 lg:mx-0'>
            <span className='Type-XXL-Medium md:Type-XXXL-Medium text-black'>
              Your favorites, perfectly printed every time.
            </span>
          </div>
          <div className='mb-8 flex gap-3'>
            <button
              type='button'
              onClick={() => handleChangeDisplay('bestsellers')}
              className={`flex w-auto items-center justify-center rounded-[20px] border-[1px] border-bright-templi-purple px-2 py-[6px] text-center  hover:shadow-md ${
                gridDisplay === 'bestsellers'
                  ? ' bg-bright-templi-purple text-pure-white hover:bg-bright-templi-purple-hover'
                  : ' bg-none text-bright-templi-purple-hover hover:border-bright-templi-purple-hover hover:bg-bright-templi-purple-hover hover:text-pure-white'
              }`}
            >
              <span className='Type-Large-Regular truncate whitespace-nowrap px-2'>
                Best Sellers
              </span>
            </button>
            <button
              type='button'
              onClick={() => handleChangeDisplay('newitems')}
              className={`flex w-auto items-center justify-center rounded-[20px] border-[1px] border-solid border-bright-templi-purple bg-none px-2 py-[6px] text-center hover:bg-bright-templi-purple-hover hover:text-pure-white hover:shadow-md ${
                gridDisplay === 'newitems'
                  ? ' bg-bright-templi-purple text-pure-white hover:bg-bright-templi-purple-hover'
                  : ' bbg-none text-bright-templi-purple-hover hover:border-bright-templi-purple-hover hover:bg-bright-templi-purple-hover hover:text-pure-white'
              }`}
            >
              <span className='Type-Large-Regular truncate whitespace-nowrap px-2'>New</span>
            </button>
          </div>
        </div>
      )}
      {!loading &&
        groups &&
        groups?.length > 0 &&
        groups?.map((group) => (
          <HomePageRelatedProductGroup
            key={block_title ?? group?.block_title?.replaceAll(' ', '_')}
            classes={classes}
            {...group}
            block_title={block_title ?? group?.block_title}
            displayOnMinicart={displayOnMinicart}
            loading={loading}
            callback={callback}
          />
        ))}
    </>
  )
}
