/* eslint-disable jsx-a11y/label-has-associated-control */
import { useFormAutoSubmit, useFormGqlMutation } from '@graphcommerce/ecommerce-ui'
import { ApolloError } from '@graphcommerce/graphql'
import { useCartQuery } from '@graphcommerce/magento-cart'
import { ApolloCustomerErrorAlert, useCustomerQuery } from '@graphcommerce/magento-customer'
import {
  GuestNewsletterToggleDocument,
  GuestNewsletterToggleMutation,
  GuestNewsletterToggleMutationVariables,
} from '@graphcommerce/magento-newsletter/components/GuestNewsletterToggle/GuestNewsletterToggle.gql'
import { GetCartEmailDocument } from '@graphcommerce/magento-newsletter/components/SignupNewsletter/GetCartEmail.gql'
import { i18n } from '@lingui/core'
import { Switch } from '@mui/material'
import axios, { AxiosError } from 'axios'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { CustomerDocument } from '../../graphql/Customer.gql'
import useCallAPI from '../../hooks/useCallAPI'
import { PrimaryButton } from '../Button'
import { BlackButton } from '../Button/ButtonStyles'
import { AlertPrompt } from '../Prompt/AlertPrompt'
import { ErrorPrompt } from '../Prompt/ErrorPrompt'
import { StandardRoundedTextField, StandardTextField } from '../TextInput/TextFieldStyles'
import { IndustryCodeEnum } from './IndustryCodeEnum'
import { handleSubscribeToNewsletter } from './KlaviyoNewsletterApiCall'

type HomePageNewsletterFormProps = {
  emailPlaceholder: string
  buttonText: string
  setIsSubscriptionSuccessful: Dispatch<SetStateAction<boolean>>
  isFromBlog?: boolean
}

const HomePageNewsletterForm: React.FC<HomePageNewsletterFormProps> = ({
  emailPlaceholder,
  buttonText,
  setIsSubscriptionSuccessful,
  isFromBlog,
}) => {
  const dashboard = useCustomerQuery(CustomerDocument, {
    fetchPolicy: 'cache-and-network',
  })
  const customer = dashboard.data?.customer

  const email = customer?.email
  const customerFirstName = customer?.firstname
  const customerLastName = customer?.lastname
  const customerIndustry = customer?.company_industry

  const [userInputEmail, setUserInputEmail] = useState<string>()
  const [checked, setChecked] = useState<boolean>(false)
  const [isSubscribing, setIsSubscribing] = useState<boolean>(false)

  const [displayPrompt, setDisplayPrompt] = useState<boolean>(false)
  const [customError, setCustomError] = useState<Error>()

  const handleError = (e: Error) => {
    if ((e as AxiosError)?.response?.status === 409) {
      setCustomError(new Error('You are already subscribed to our newsletter.'))
    } else {
      setCustomError(
        new Error(
          'We encountered an error while processing your subscription. We will get this issue fixed ASAP.',
        ),
      )
    }
    console.log(e)
  }

  const form = useFormGqlMutation<
    GuestNewsletterToggleMutation,
    GuestNewsletterToggleMutationVariables & { isSubscribed?: boolean }
  >(
    GuestNewsletterToggleDocument,
    {
      defaultValues: {
        email: userInputEmail,
        isSubscribed: true,
      },
      mode: 'onChange',
      onComplete: ({ errors, data }) => {
        if (!errors) {
          setIsSubscriptionSuccessful(true)
        }
      },
    },
    { errorPolicy: 'all' },
  )
  const { handleSubmit, formState, error } = form
  const submit = handleSubmit(() => {})
  useFormAutoSubmit({ form, submit })

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setDisplayPrompt(true)
    }
  }, [formState.isSubmitSuccessful])

  return (
    <div className='flex flex-col gap-2'>
      <form
        className='z-10 mt-5 flex flex-col items-center justify-center gap-3 md:gap-8'
        onSubmit={submit}
        noValidate
      >
        <label htmlFor='emailInput' className='sr-only'>
          {i18n._('Email Address')}
        </label>

        <StandardRoundedTextField
          id='emailInput'
          type='email'
          variant='outlined'
          className='mt-3 w-full'
          defaultValue={email}
          label={emailPlaceholder}
          value={userInputEmail}
          onChange={(e) => setUserInputEmail(e?.target.value)}
          disabled={formState.isSubmitting}
        />
        <div>
          <p className='text-neutral-900 Type-Large-Regular text-center text-opacity-90'>
            {i18n._(
              'By subscribing, you are consenting to marketing communication. To learn more, visit our ',
            )}
            <a
              href='templiservice/privacy-policy'
              className='underline hover:text-material-ui-dark-blue'
            >
              Privacy Policy
            </a>
            ,
          </p>
        </div>
        <PrimaryButton
          type='button'
          className='w-full max-w-[260px] md:w-auto md:min-w-[220px]'
          onClick={async () => {
            if (customer) {
              await handleSubscribeToNewsletter(
                setIsSubscribing,
                setDisplayPrompt,
                handleError,
                userInputEmail ?? email ?? '',
                customerFirstName ?? '',
                customerLastName ?? '',
                [customerIndustry ?? ''],
                !!customer,
                { subscribed_from_blog: true },
              )
            } else {
              setChecked(true)
              await submit()
            }
          }}
          loading={formState.isSubmitting || isSubscribing}
          disabled={formState.isSubmitting}
        >
          {buttonText}
        </PrimaryButton>
      </form>
      <ErrorPrompt error={error || (customError as ApolloError)} />
      <AlertPrompt
        title={i18n._('Newsletter Sign up')}
        message={i18n._('Thank you for signing up for our newsletter!')}
        isDisplayed={displayPrompt}
        setIsDisplayed={setDisplayPrompt}
      />
    </div>
  )
}

// eslint-disable-next-line import/no-default-export
export default HomePageNewsletterForm
