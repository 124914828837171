import { Image } from '@graphcommerce/image'
import { i18n } from '@lingui/core'
import { Paper } from '@mui/material'
import { ButtonBack, ButtonNext, CarouselProvider, Slider } from 'pure-react-carousel'
import { useState } from 'react'
import { Slide } from 'react-slideshow-image'
import arrowLeft from '../../../assets/images/arrow-left-outline.svg'
import arrowRight from '../../../assets/images/arrow-right-outline.svg'
import useWindowSize from '../../../hooks/useWindowSize'
import { RowBrandsBannerFragment } from './RowBrandsBanner.gql'
import 'react-slideshow-image/dist/styles.css'

export type BrandProps = {
  src: string
  url: string
  alt: string
}

type RowBrandsBannerProps = RowBrandsBannerFragment

export function RowBrandsBanner(props: RowBrandsBannerProps) {
  const { brands, sectionLabel } = props
  const { isMobile } = useWindowSize()
  return (
    <div className='flex w-full items-center justify-center bg-none'>
      <div className='mx-[10px] my-[20px] w-full max-w-maxContentWidth rounded-md md:mx-[20px] md:my-5 md:px-[60px] '>
        <div className='w-full'>
          <Slide
            cssClass=''
            autoplay
            duration={0}
            transitionDuration={5000}
            infinite
            indicators={false}
            slidesToShow={isMobile ? 4 : 6}
            arrows={false}
          >
            {brands?.map((brand, index) => (
              <div
                key={brand.id}
                id={`slide${index}${brand.id}`}
                className='mx-5 flex h-full w-full items-center justify-center justify-self-start px-5 md:mx-8 lg:mx-5'
              >
                <a href={brand.url || '#'} target='_blank' rel='noreferrer'>
                  <Image
                    src={brand.image?.url || '/'}
                    alt={brand.alt || ''}
                    layout='fixed'
                    height={200}
                    className='fill-70-grey text-70-grey opacity-60 md:scale-75'
                  />
                </a>
              </div>
            ))}
          </Slide>
        </div>
      </div>
    </div>
  )
}
