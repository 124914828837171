import { Image } from '@graphcommerce/image'
import { CustomerDocument, useCustomerQuery } from '@graphcommerce/magento-customer'
import { i18n } from '@lingui/core'
import { Paper } from '@mui/material'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import arrowLeft from '../../../assets/images/arrow-left-outline.svg'
import arrowRight from '../../../assets/images/arrow-right-outline.svg'
import bgImage from '../../../assets/images/templi_experience.png'
import useWindowSize from '../../../hooks/useWindowSize'
import { HomePageReviewItem } from '../../Reviews/HomePageReviewItem'
import { ReviewItem } from '../../Reviews/ReviewItem'
import { RowReviewHighlightFragment } from './RowReviewHighlight.gql'
import { reviewHighlightsData } from './data'

type RowReviewHighlightProps = RowReviewHighlightFragment

export function RowReviewHighlight(props: RowReviewHighlightProps) {
  const { id, reviewItems } = props
  const dashboard = useCustomerQuery(CustomerDocument, {
    fetchPolicy: 'cache-and-network',
  })
  const customer = dashboard.data?.customer
  const { isMobile } = useWindowSize()

  return (
    <div className='mt-[52px] flex w-full flex-col items-center justify-center'>
      <div className='bg-pure white mx-[10px] mb-4 w-full max-w-maxContentWidth rounded-md px-5 md:mx-[20px] md:px-[60px] '>
        <div className='relative mt-5 flex flex-col items-start gap-5 md:flex-row lg:mx-0'>
          <div>
            <div className='flex flex-col items-start justify-start'>
              <div className='flex w-full flex-col items-center justify-start gap-2 md:flex-row'>
                <span className='Type-XXL-Medium md:Type-XXXL-Medium text-black'>
                  {i18n._('The Templi Experience')}
                </span>
                <div className='ml-6 flex w-auto items-center justify-center rounded-[20px] bg-[#F2E5FF] px-2 py-2 text-center text-pure-white'>
                  <span className='Type-Large-Regular truncate whitespace-nowrap px-2 text-[#613394]'>
                    <b>Over 300+</b> 5 star reviews ⭐
                  </span>
                </div>
                <div className='flex w-auto items-center justify-center rounded-[20px] bg-[#F2E5FF] px-2 py-2 text-center text-pure-white'>
                  <span className='Type-Large-Regular truncate whitespace-nowrap px-2 text-[#613394]'>
                    Customer Satisfaction Guaranteed ✨
                  </span>
                </div>
              </div>
              <p className='Type-XL-Regular my-5 w-full'>
                Custom printing for hospitality and retail, delivering expert care and stunning,
                brand-elevating results.
              </p>
            </div>

            <CarouselProvider
              naturalSlideWidth={5}
              naturalSlideHeight={1}
              totalSlides={reviewItems?.length ?? 0}
              // eslint-disable-next-line no-nested-ternary
              visibleSlides={isMobile ? 1 : 4}
              infinite
              isIntrinsicHeight
              className='grid w-full grid-cols-[5fr_90fr_5fr] gap-[10px] self-center md:w-full'
            >
              <ButtonBack className='flex items-center justify-center justify-self-start hover:scale-110'>
                <Image
                  src={arrowLeft}
                  alt='Back'
                  layout='fixed'
                  unoptimized
                  height={25}
                  width={25}
                />
              </ButtonBack>
              <Slider className='gap-[40px] align-middle'>
                {reviewItems?.map((review, index) => (
                  <Slide
                    index={index}
                    className='mx-5 flex h-auto w-[200px] items-center justify-center'
                  >
                    <HomePageReviewItem
                      id={0}
                      created_at={review.dateCreated}
                      verified_buyer
                      images_data={[]}
                      {...review}
                      customer={customer}
                      displayVotes={false}
                      displayImages={false}
                      avatar_url={review.avatar?.url}
                      displayDate={false}
                      displayReplies={false}
                      isHomePageItem
                    />
                  </Slide>
                ))}
              </Slider>
              <ButtonNext className='flex items-center justify-center justify-self-end hover:scale-110'>
                <Image
                  src={arrowRight}
                  alt='Back'
                  layout='fixed'
                  unoptimized
                  height={25}
                  width={25}
                />
              </ButtonNext>
            </CarouselProvider>
          </div>
        </div>
      </div>
    </div>
  )
}
