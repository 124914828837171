import PageLink from 'next/link'
import { Button, PrimaryButton } from '../../Button'
import { SecondaryHeaderButton } from '../../Button/ButtonStyles'
import { Icon } from '../../Layout/Icon'
import { HeroBanner } from './HeroBanner'
import { RowHeroBannerFragment } from './RowHeroBanner.gql'

export function RowHeroBanner(props: RowHeroBannerFragment) {
  const { banners, headerText, pageLinks, headerTitle } = props
  return (
    <HeroBanner
      pageLinks={pageLinks.map((pageLink, index) => (
        <PageLink key={pageLink.url} href={pageLink.url} passHref>
          {index === 0 && (
            <PrimaryButton type='button' className='w-auto bg-new-templi-purple px-3' size='small'>
              <span className='capitalize'>{pageLink.title?.toLocaleLowerCase()}</span>
              <Icon name='arrow_forward' className='ml-[10px] text-[14px] font-bold' />
            </PrimaryButton>
          )}
          {/* {index > 0 && (
            <SecondaryHeaderButton
              type='button'
              className='w-auto bg-secondary-templi-yellow'
              variant='contained'
              size='small'
            >
              {pageLink.title?.toLocaleLowerCase()}
            </SecondaryHeaderButton>
          )} */}
        </PageLink>
      ))}
      headerText={headerText}
      headerTitle={headerTitle}
      banners={banners}
    />
  )
}
