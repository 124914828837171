/* eslint-disable no-nested-ternary */
import { Image } from '@graphcommerce/image'
import { Paper, Tooltip } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import arrowLeft from '../../../assets/images/arrow-left-outline.svg'
import arrowRight from '../../../assets/images/arrow-right-outline.svg'
import useWindowSize from '../../../hooks/useWindowSize'
import { PrimaryButton } from '../../Button'
import { ResponsiveImageGridItem } from '../../Image/ResponsiveImageGridItem'
import { RowFeaturedCategoryFragment } from './RowFeaturedCategory.gql'

type RowFeaturedCategoryProps = RowFeaturedCategoryFragment

export function RowFeaturedCategory(props: RowFeaturedCategoryProps) {
  const { categoryItems, sectionLabel } = props
  const router = useRouter()
  const handleClick = async (href: string) => {
    await router.push(href)
  }
  const { isMobile } = useWindowSize()

  return (
    <div className='flex w-full items-center justify-center'>
      <div className='md:bg-white mx-[10px] mb-4 mt-4 w-full max-w-maxContentWidth rounded-md px-[20px] md:mx-[20px] md:px-[60px]'>
        <div className='relative flex items-center pb-6 pt-10 lg:mx-0'>
          <span className='Type-XXL-Medium md:Type-XXXL-Medium text-black'>{sectionLabel}</span>
        </div>
        <CarouselProvider
          naturalSlideWidth={5}
          naturalSlideHeight={1}
          totalSlides={categoryItems?.length ?? 0}
          // eslint-disable-next-line no-nested-ternary
          visibleSlides={
            isMobile ? 2 : (categoryItems?.length ?? 0) < 4 ? categoryItems?.length ?? 0 : 5
          }
          infinite
          isIntrinsicHeight
          className='grid w-auto grid-cols-[5fr_90fr_5fr] gap-[10px] self-center md:w-full'
        >
          <ButtonBack className='flex items-center justify-center justify-self-start hover:scale-110'>
            <Image src={arrowLeft} alt='Back' layout='fixed' unoptimized height={25} width={25} />
          </ButtonBack>
          <Slider className='gap-[40px] align-middle'>
            {categoryItems?.map((category, index) => (
              <Slide
                index={index}
                className='flex w-full max-w-none items-center justify-center md:max-w-[240px]'
              >
                <Link
                  href={category.categoryImage?.url ?? ''}
                  key={category.categoryLabel}
                  className='h-[150px] w-[150px] text-center text-black hover:text-70-grey xl:h-[180px] xl:w-[180px]'
                >
                  <ResponsiveImageGridItem
                    src={category.categoryImage?.image?.url ?? ''}
                    alt={category.categoryImage?.image?.alt ?? ''}
                    className='rounded-[50%]'
                    rounded
                  />

                  <span className='md:Type-XL-Medium Type-Large-Medium mt-2 flex w-full items-center justify-center text-center md:my-3'>
                    {category.categoryLabel}
                  </span>
                </Link>
              </Slide>
            ))}
          </Slider>
          <ButtonNext className='flex items-center justify-center justify-self-end hover:scale-110'>
            <Image src={arrowRight} alt='Back' layout='fixed' unoptimized height={25} width={25} />
          </ButtonNext>
        </CarouselProvider>
        {/* <div className='flex flex-col items-center justify-items-center gap-[20px] rounded-[12px] pb-5 lg:grid lg:grid-cols-5 lg:gap-10 lg:pb-7'>
          {categoryItems?.map((category) => (
            <Link
              href={category.categoryImage?.url ?? ''}
              key={category.categoryLabel}
              className='w-full  text-black hover:text-material-ui-blue'
            >
              <ResponsiveImageGridItem
                src={category.categoryImage?.image?.url ?? ''}
                alt={category.categoryImage?.image?.alt ?? ''}
                aspect='4/3'
              />

              <span className='md:Type-XXL-Regular Type-XXL-Regular mt-2 text-center md:my-3'>
                {category.categoryLabel}
              </span>
            </Link>
          ))}
        </div> */}
        {/* <div className='mb-6 flex w-full items-center justify-center'>
          <PrimaryButton onClick={() => handleClick('/search')}>View All</PrimaryButton>
        </div> */}
      </div>
    </div>
  )
}
