/* eslint-disable no-nested-ternary */
import { Image } from '@graphcommerce/image'
import { Paper, Tooltip } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import arrowLeft from '../../../assets/images/arrow-left-outline.svg'
import arrowRight from '../../../assets/images/arrow-right-outline.svg'
import useWindowSize from '../../../hooks/useWindowSize'
import { PrimaryButton } from '../../Button'
import { ResponsiveImageGridItem } from '../../Image/ResponsiveImageGridItem'
import { RowSocialsHighlightFragment } from './RowSocialsHighlight.gql'

type RowSocialsHighlightProps = RowSocialsHighlightFragment

export function RowSocialsHighlight(props: RowSocialsHighlightProps) {
  const { socials } = props
  const router = useRouter()
  const handleClick = async (href: string) => {
    await router.push(href)
  }
  const { isMobile } = useWindowSize()

  return (
    <div className='flex w-full items-center justify-center'>
      <div className='md:bg-white mx-[10px] mb-4 w-full max-w-maxContentWidth rounded-md px-[20px] md:mx-[20px] md:px-[60px]'>
        <div className='relative flex items-center pb-8 lg:mx-0'>
          <span className='Type-XXL-Medium md:Type-XXXL-Medium text-black'>
            Your design, our products
          </span>
        </div>
        <CarouselProvider
          naturalSlideWidth={5}
          naturalSlideHeight={1}
          totalSlides={socials?.length ?? 0}
          // eslint-disable-next-line no-nested-ternary
          visibleSlides={isMobile ? 1 : 4}
          infinite
          isIntrinsicHeight
          className='grid w-auto grid-cols-[5fr_90fr_5fr] gap-[10px] self-center md:w-full'
        >
          <ButtonBack className='flex items-center justify-center justify-self-start hover:scale-110'>
            <Image src={arrowLeft} alt='Back' layout='fixed' unoptimized height={25} width={25} />
          </ButtonBack>
          <Slider className='gap-[40px] align-middle'>
            {socials?.map((social, index) => (
              <Slide
                index={index}
                className='mx-[10px] flex w-full max-w-none items-center justify-center px-[10px] md:max-w-[260px]'
              >
                <Link
                  href={social.url ?? ''}
                  target='_blank'
                  key={social.url}
                  className='flex h-[150px] w-[150px] flex-col text-center text-70-grey hover:text-material-ui-blue md:h-[140px] md:w-[140px] lg:h-[180px] lg:w-[180px] xl:h-[220px] xl:w-[220px]'
                >
                  <ResponsiveImageGridItem
                    src={social.banner?.image?.url ?? ''}
                    alt={social.banner?.image?.alt ?? ''}
                    aspect='1'
                  />

                  <span className='md:Type-XL-Regular Type-Large-Regular mt-2 flex w-full items-start justify-start md:my-3'>
                    {social?.title}
                  </span>
                </Link>
              </Slide>
            ))}
          </Slider>
          <ButtonNext className='flex items-center justify-center justify-self-end hover:scale-110'>
            <Image src={arrowRight} alt='Back' layout='fixed' unoptimized height={25} width={25} />
          </ButtonNext>
        </CarouselProvider>
      </div>
    </div>
  )
}
