// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Image from 'next/image'
import affiliateProgramRibbon from '../../assets/images/affiliate-program-badge.svg'
import grid1Image1 from '../../assets/images/affiliate-program-grid-1.png'
import grid1Image2a from '../../assets/images/affiliate-program-grid-2a.png'
import grid1Image2b from '../../assets/images/affiliate-program-grid-2b.png'

import grid1Image3 from '../../assets/images/affiliate-program-grid-3.png'
import { Icon } from '../Layout/Icon'

export function ReferralProgram() {
  return (
    <section className='bg-white flex w-full flex-col items-center justify-center bg-none px-3 py-9 shadow-sm md:px-[10%]'>
      <div className='mx-[10px] flex w-full max-w-maxContentWidth flex-col justify-center gap-x-12 gap-y-5 px-5 md:mx-[20px] md:grid md:grid-cols-2 md:px-[60px]'>
        <div className='flex flex-col items-start justify-center gap-4'>
          <Image
            alt='affiliate program'
            src={affiliateProgramRibbon}
            layout='fixed'
            height={30}
            width={230}
          />
          <span className='Type-XXL-Medium md:Type-XXXL-Medium mb-2 text-black'>
            Do you often refer clients to Templi?
          </span>
          <span className='Type-Large-Regular mb-4'>
            A reliable print partner can greatly reduce your time spent getting your design into
            production. Join Friends of Templi - our affiliate program, where you are rewarded for
            your client connections ✨
          </span>
          <a
            href='become-a-friend-of-templi'
            className='Type-Large-Regular group flex items-center justify-center text-new-templi-purple hover:text-new-templi-purple-hover'
            target='_blank'
          >
            Learn more
            <Icon
              name='arrow_forward '
              className='ml-1 text-[14px] font-bold duration-500 group-hover:translate-x-1'
            />
          </a>
        </div>
        <div className='mx-5 hidden w-full items-center justify-center gap-3 md:grid md:grid-cols-3'>
          <div className='flex w-full items-center justify-end'>
            <Image
              alt='affiliate program'
              src={grid1Image1}
              layout='fixed'
              height={30}
              width={230}
              style={{
                boxShadow: '0px 4px 40px 0px #E6CDFF',
              }}
              className='rounded-[24px]'
            />
          </div>

          <div className='hidden flex-col items-center justify-center gap-5 md:flex'>
            <div className='flex items-end'>
              <Image
                alt='affiliate program'
                src={grid1Image2a}
                layout='fixed'
                height={30}
                width={230}
                style={{
                  boxShadow: '0px 4px 40px 0px #E6CDFF',
                }}
                className='rounded-[24px]'
              />
            </div>
            <div className='flex items-end'>
              <Image
                alt='affiliate program'
                src={grid1Image2b}
                layout='fixed'
                height={30}
                width={230}
                style={{
                  boxShadow: '0px 4px 40px 0px #E6CDFF',
                }}
                className='rounded-[24px]'
              />
            </div>
          </div>
          <Image
            alt='affiliate program'
            src={grid1Image3}
            layout='fixed'
            height={30}
            width={230}
            style={{
              boxShadow: '0px 4px 40px 0px #E6CDFF',
            }}
            className='rounded-[24px]'
          />
        </div>
      </div>
    </section>
  )
}
