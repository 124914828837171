import { grid } from '@chakra-ui/react'
import { Paper } from '@mui/material'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { HomePageRelatedProductGroups } from '../Amasty/HomePageRelatedProductGroups'
import { RelatedProductGroups } from '../Amasty/RelatedProductGroups'
import { PrimaryButton } from '../Button'

type BestSellersProps = {
  className?: string
  noHorizontalMargins?: boolean
}
export function BestSellers(props: BestSellersProps) {
  const { className, noHorizontalMargins } = props
  const router = useRouter()
  const [gridDisplay, setGridDisplay] = useState<'bestsellers' | 'newitems'>('bestsellers')
  const handleClick = async (href: string) => {
    await router.push(href)
  }

  return (
    <div className='mb-4 flex w-full items-center justify-center'>
      <div
        className={`${
          noHorizontalMargins ? 'mx-0' : 'mx-[10px] px-5 md:mx-[20px] md:px-[60px]'
        } w-full max-w-maxContentWidth rounded-md ${className}`}
      >
        <HomePageRelatedProductGroups
          position={gridDisplay === 'bestsellers' ? 'home_bottom' : 'home_top'}
          classes={{
            list: 'grid md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 lg:gap-10 grid-cols-2 gap-[10px]',
          }}
          setGridDisplay={setGridDisplay}
          gridDisplay={gridDisplay}
        />
      </div>
    </div>
  )
}
